import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { LanguageLocale, NodeLocale } from "@components/Shared/model/LanguageContent";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Accordion, AccordionTab } from "@ryerson/frontend.expansion";
import { Link, ButtonLink } from "@ryerson/frontend.navigation";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import SocialMedia from "@components/NewsEvents/SocialMedia/SocialMedia";
import { Button } from "@ryerson/frontend.button";
import styled from "@emotion/styled";
import Map from "@components/Locations/Map/Map";
import { PopularContent } from "@components/Blog/PopularContent";
import { useStaticQuery, graphql } from "gatsby";
import { css } from "@emotion/react";
import StayTunedComponent from "@components/Shared/StayTuned/StayTuned";

export type ContenfulEventNode = {
	callToAction: any;
	company: string[];
	date: string;
	description: any;
	eventDates: string;
	eventLocation: string;
	externalAction?: string;
	externalActionUrl?: string;
	heroImage: any;
	internalAction: string;
	internalActionUrl: string;
	mapLocation: any;
	thirdPartyLinkUrl?: string;
	thirdPartyLinkLabel?: string;
	slug: string;
	title: string;
	node_locale: NodeLocale;
};

export type StaticEventContent = {
	moreInformation: string;
	rsvp: string;
	generalTitle: string;
	withRyerson: string;
	registerToday: string;
	dateLabel: string;
	locationLabel: string;
	howToSignUp: string;
	upcomingLabel: string;
	relatedLabel: string;
	contactQuestion: string;
	allEventsUrl: string;
	allEventsLabel: string;
	contactLinkLabel: string;
	contactLinkUrl: string;
};

export type ContentfulNode = {
	node: ContenfulEventNode;
};

export type EventProps = {
	contentfulContent: ContentfulNode[];
	content: StaticEventContent;
};

const MobileHeroImage = styled.div`
	display: block;
	width: 100%;
	margin-top: 34px;
	height: 190px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
`;

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 0px;
			}
		`;
	}}
`;

const Divider = styled.hr`
	margin-top: 0px;
	margin-bottom: 0px;
	opacity: 0.1;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 10px;
`;

const GiantSpacer = styled.div`
	display: block;
	width: 100%;
	height: 90px;
`;

const HeroLeft = styled.div`
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 350px);
`;

const HeroRight = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 350px;
	padding-top: 85px;
	box-sizing: border-box;
	text-align: right;
`;

const Left = styled.div`
	display: inline-block;
	width: calc(100% - 300px);
	position: relative;
	z-index: 1;
	vertical-align: top;
	padding: 51px 40px 73px 40px;
	box-sizing: border-box;
	height: auto;
	border-radius: 2px;
`;

const Right = styled.div`
	display: inline-block;
	width: 290px;
	vertical-align: top;
	position: relative;
	z-index: 1;
	margin-left: 10px;
`;

const RightContainer = styled.div`
	display: block;
	width: 100%;
	height: auto;
	padding: 15px 22px 24px 22px;
	box-sizing: border-box;
	border-radius: 2px;
`;

const Social = styled.div`
	width: 20px;
	height: auto;
	left: -36px;
	position: absolute;
`;

const HeroImage = styled.div`
	display: block;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	height: 440px;
	position: absolute;
	left: 0;
`;

const MapContainer = styled.div`
	display: block;
	width: 100%;
	border-radius: 2px;
	height: 340px;
	overflow: hidden;
`;

const UpcomingEvent = styled.div`
	display: block;
	width: 100%;
	height: 102px;
`;

const UpcomingEventImage = styled.div`
	display: inline-block;
	width: 80px;
	background-repeat: no-repeat;
	background-position: right center;
	background-size: cover;
	vertical-align: top;
	height: 102px;
`;

const UpcomingEventDetails = styled.div`
	display: inline-block;
	width: calc(100% - 80px);
	padding: 20px 0px;
	vertical-align: top;
	height: 102px;
	box-sizing: border-box;
`;

const stayTunedParent = "Event";

const Events: React.FC<EventProps> = ({ contentfulContent, content }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();

	const [socialReference, setSocialReference] = React.useState<any>(React.createRef());
	const [socialTop, setSocialTop] = React.useState<number>(0);
	React.useEffect(() => {
		if (window !== undefined && document !== undefined) {
			window.addEventListener("scroll", function () {
				if (socialReference.current) {
					let offset = socialReference.current.getBoundingClientRect();
					let startingTop = offset.top - document.body.getBoundingClientRect().top;
					if (
						window.scrollY + 120 >= startingTop &&
						window.scrollY + 265 < socialReference.current.offsetHeight + startingTop
					) {
						setSocialTop(window.scrollY - startingTop + 120);
					} else if (
						socialReference.current.offsetHeight + startingTop <=
						window.scrollY + 265
					) {
						setSocialTop(socialReference.current.offsetHeight - 145);
					} else {
						setSocialTop(0);
					}
				}
			});
		}
		if (!socialReference) setSocialReference(React.createRef());
	}, []);

	const allEventsMeta = useStaticQuery(graphql`
		query AllEventsMetaQuery {
			allContentfulEvent(sort: { order: DESC, fields: contentful_id }) {
				nodes {
					title
					slug
					contentful_id
					date
					eventDates
					node_locale
					heroImage {
						file {
							url
						}
					}
				}
			}
		}
	`);
	let upcomingEvents: any = [];
	let previousId: any = null;
	let i = -1;
	allEventsMeta?.allContentfulEvent?.nodes?.forEach((node: any) => {
		if (previousId !== node.contentful_id) {
			i++;
			upcomingEvents[i] = {};
		}
		upcomingEvents[i][node.node_locale.split("-").shift()] = node;
		upcomingEvents[i].date = node.date;
		previousId = node.contentful_id;
	});

	upcomingEvents.sort(function (a: any, b: any) {
		return new Date(a.date).valueOf() - new Date(b.date).valueOf();
	});
	let x: number = 0;
	const upcomingEventsToDisplay: any[] = [];
	upcomingEvents.forEach((event: any, index: number) => {
		if (x < 2) {
			if (new Date(event.date) > new Date()) {
				upcomingEventsToDisplay.push(event);
				x++;
			}
		}
	});
	const [languageContent, setLanguageContent] = React.useState<ContenfulEventNode>(
		contentfulContent[0].node
	);
	React.useEffect(() => {
		contentfulContent.forEach((data: ContentfulNode) => {
			if (LanguageLocale[data?.node?.node_locale] === language)
				setLanguageContent(data?.node);
		});
	}, [language]);
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="20px">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<HeroLeft>
						<Typography
							variant="h1"
							css={css`
								margin-top: 20px;
								margin-bottom: 30px;
								display: block;
							`}
							type="tertiary"
						>
							{languageContent?.title}
						</Typography>
						<Media lessThan="xl">
							<SocialMedia orientation="horizontal" type="tertiary" />
							<Spacer />
							<Spacer />
						</Media>
						{languageContent?.description &&
						languageContent.description.description.length > 0 ? (
							<Typography
								css={css`
									margin-bottom: 20px;
								`}
								type="tertiary"
								size="lg"
								variant="div"
							>
								{languageContent.description.description}
							</Typography>
						) : (
							<></>
						)}
						{languageContent?.callToAction &&
						languageContent.callToAction.callToAction.length > 0 ? (
							<Typography type="tertiary" size="md" variant="div">
								{languageContent.callToAction.callToAction}
							</Typography>
						) : (
							<></>
						)}
						<GiantSpacer />
					</HeroLeft>
					<HeroRight>
						{languageContent?.thirdPartyLinkLabel &&
						languageContent?.thirdPartyLinkUrl ? (
							<Typography
								css={css`
									margin-bottom: 25px;
								`}
								variant="div"
								size="md"
								type="tertiary"
							>
								{content.moreInformation}{" "}
								<Link
									gatsby={false}
									to={languageContent.thirdPartyLinkUrl}
									type="tertiary"
								>
									{languageContent.thirdPartyLinkLabel}
								</Link>
							</Typography>
						) : (
							<></>
						)}
						{languageContent?.externalAction && languageContent?.externalActionUrl ? (
							<ButtonLink
								orientation="right"
								type="tertiary"
								label={languageContent.externalAction}
								to={languageContent.externalActionUrl}
								gatsby={false}
								linkType="external"
							/>
						) : (
							<></>
						)}
					</HeroRight>
				</ContentSection>
				<ContentSection type="secondary" vPadding="0px">
					<HeroImage
						css={css`
							background-image: url(${languageContent.heroImage?.file
								? languageContent.heroImage?.file?.url
								: ""});
						`}
					/>
					<GiantSpacer />
					<Spacer />
					<Left
						ref={socialReference}
						css={css`
							background-color: ${theme.colors.primary.background};
						`}
					>
						<Media greaterThan="lg">
							<Social
								css={css`
									top: ${socialTop + "px"};
								`}
							>
								<SocialMedia orientation="vertical" type="tertiary" />
							</Social>
						</Media>
						<Typography
							variant="h2"
							type="primary"
							css={css`
								margin-bottom: 35px;
							`}
						>
							{content.generalTitle}
						</Typography>
						<Divider
							css={css`
								color: ${theme.colors.primary.darkerGray};
								margin-top: 0px;
								margin-bottom: 35px;
							`}
						/>
						<Flex
							css={css`
								width: calc(100% - 85px);
								margin-bottom: 16px;
							`}
							direction="row"
							justifyContent="space-between"
						>
							<FlexItem>
								<Typography variant="div" size="md" type="primary">
									{content.dateLabel}
									{": "}
									<Typography
										variant="span"
										size="md"
										color={theme.colors.primary.header}
										weight="bold"
									>
										{languageContent?.eventDates}
									</Typography>
								</Typography>
							</FlexItem>
							<FlexItem>
								<Typography variant="div" size="md" type="primary">
									{content.locationLabel}
									{": "}
									<Typography
										variant="span"
										size="md"
										color={theme.colors.primary.header}
										weight="bold"
									>
										{languageContent?.eventLocation}
									</Typography>
								</Typography>
							</FlexItem>
						</Flex>
						{languageContent?.mapLocation &&
						languageContent?.mapLocation.lon &&
						languageContent?.mapLocation.lat ? (
							<MapContainer>
								<Map
									googleKey={process.env.GATSBY_GOOGLE_MAPS_KEY ?? ""}
									mapData={[
										{
											location: {
												lat: languageContent.mapLocation.lat,
												lng: languageContent.mapLocation.lon,
											},
											name: languageContent.eventLocation,
											id: languageContent.eventLocation,
										},
									]}
									zoomLevel={16}
								/>
							</MapContainer>
						) : (
							<></>
						)}
						<Typography
							variant="h2"
							type="primary"
							css={css`
								margin-top: 72px;
								margin-bottom: 40px;
							`}
						>
							{content.howToSignUp}
						</Typography>
						<Flex
							css={css`
								max-width: 616px;
							`}
							direction="row"
							justifyContent="space-between"
						>
							<FlexItem>
								{languageContent?.internalAction ? (
									<>
										<Typography variant="div" type="primary" size="sm">
											<Typography
												variant="span"
												color={theme.colors.primary.header}
												weight="bolder"
												css={css`
													font-size: 20px;
													margin-right: 10px;
												`}
											>
												{"1"}
											</Typography>

											<Typography variant="span" type="primary" size="sm">
												{languageContent.internalAction}
											</Typography>
											<Link to={languageContent.internalActionUrl}>
												<Button
													css={css`
														margin-top: 16px;
													`}
													onClick={() => {}}
													size="xl"
													label={content.rsvp + " " + content.withRyerson}
												/>
											</Link>
										</Typography>
									</>
								) : (
									<>
										<FlexItem>
											{languageContent?.externalAction &&
											languageContent?.externalActionUrl ? (
												<Typography variant="div" type="primary" size="sm">
													<Typography
														variant="span"
														color={theme.colors.primary.header}
														weight="bolder"
														css={css`
															font-size: 20px;
															margin-right: 10px;
														`}
													>
														{"1"}
													</Typography>
													<Typography
														variant="span"
														type="primary"
														size="sm"
													>
														{languageContent.externalAction}
													</Typography>
													<Link to={languageContent.externalActionUrl}>
														<Button
															css={css`
																margin-top: 16px;
															`}
															onClick={() => {}}
															size="xl"
															label={content.registerToday}
														/>
													</Link>
												</Typography>
											) : (
												<>
													<FlexItem>
														{languageContent?.externalAction &&
														languageContent?.externalActionUrl ? (
															<Typography
																variant="div"
																type="primary"
																size="sm"
															>
																<Typography
																	variant="span"
																	color={
																		theme.colors.primary.header
																	}
																	weight="bolder"
																	css={css`
																		font-size: 20px;
																		margin-right: 10px;
																	`}
																>
																	{"2"}
																</Typography>
																<Typography
																	variant="span"
																	type="primary"
																	size="sm"
																>
																	{languageContent.externalAction}
																</Typography>
																<Link
																	to={
																		languageContent.externalActionUrl
																	}
																>
																	<Button
																		css={css`
																			margin-top: 16px;
																		`}
																		onClick={() => {}}
																		size="xl"
																		label={
																			content.registerToday
																		}
																	/>
																</Link>
															</Typography>
														) : (
															<></>
														)}
													</FlexItem>
												</>
											)}
										</FlexItem>
									</>
								)}
							</FlexItem>
						</Flex>
					</Left>
					<Right>
						{languageContent?.internalAction ? (
							<>
								<RightContainer
									css={css`
										background-color: ${theme.colors.primary.background};
										margin-bottom: 10px;
									`}
								>
									<Typography
										variant="h3"
										css={css`
											font-size: 20px;
											margin-bottom: 18px;
										`}
									>
										{content.rsvp}
									</Typography>
									<Divider
										css={css`
											color: ${theme.colors.primary.darkerGray};
											margin-top: 0px;
											margin-bottom: 24px;
										`}
									/>
									<Typography
										variant="div"
										size="xs"
										type="primary"
										css={css`
											margin-bottom: 10px;
										`}
									>
										{languageContent.internalAction}
									</Typography>
									<Link to={languageContent.internalActionUrl}>
										<Button
											size="lg"
											fullwidth={true}
											label={content.rsvp + " " + content.withRyerson}
											onClick={() => {}}
										/>
									</Link>
								</RightContainer>
							</>
						) : (
							<></>
						)}
						<RightContainer
							css={css`
								background-color: ${theme.colors.secondary.background};
								margin-bottom: 10px;
							`}
						>
							<Typography
								variant="div"
								size="sm"
								css={css`
									margin-bottom: 10px;
								`}
							>
								{content.contactQuestion}
							</Typography>
							<ButtonLink
								linkType="internal"
								to={content.contactLinkUrl}
								type="secondary"
								label={content.contactLinkLabel}
								orientation="right"
								size="sm"
							/>
						</RightContainer>
						<RightContainer
							css={css`
								background-color: ${theme.colors.primary.darkerGray};
								margin-bottom: 10px;
							`}
						>
							<Typography
								variant="h3"
								type="tertiary"
								css={css`
									margin-bottom: 20px;
									font-size: 20px;
								`}
							>
								{content.upcomingLabel}
							</Typography>
							{upcomingEventsToDisplay.map((event: any, index: number) => {
								return (
									<React.Fragment key={index}>
										<Divider
											css={css`
												color: ${theme.colors.primary.white};
												margin-top: 0px;
												margin-bottom: 0px;
											`}
										/>
										<UpcomingEvent>
											<UpcomingEventDetails>
												<Flex
													direction="column"
													justifyContent="space-between"
													css={css`
														height: 100%;
													`}
												>
													<FlexItem>
														<Typography
															variant="div"
															weight="bold"
															size="md"
															color={theme.colors.tertiary.header}
														>
															{event[language]?.title}
														</Typography>
													</FlexItem>
													<FlexItem>
														<Typography
															variant="div"
															size="xs"
															type="tertiary"
														>
															{event[language]?.eventDates}
														</Typography>
													</FlexItem>
												</Flex>
											</UpcomingEventDetails>
											<UpcomingEventImage
												css={css`
													background-image: url(${event[language]
														?.heroImage?.file
														? event[language].heroImage?.file?.url
														: ""});
												`}
											/>
										</UpcomingEvent>
									</React.Fragment>
								);
							})}
							<Divider
								css={css`
									color: ${theme.colors.primary.white};
									margin-top: 0px;
									margin-bottom: 18px;
								`}
							/>
							<ButtonLink
								to={content.allEventsUrl}
								linkType="internal"
								type="tertiary"
								orientation="right"
								size="sm"
								label={content.allEventsLabel}
							/>
						</RightContainer>
						<PopularContent title={content.relatedLabel} excludeSlug={""} />
						<Spacer
							css={css`
								height: 10px;
							`}
						/>
						<StayTunedComponent parent={stayTunedParent} slim={true} />
					</Right>
					<GiantSpacer
						css={css`
							height: 140px;
						`}
					/>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary" vPadding="28px">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						css={css`
							margin-top: 14px;
							margin-bottom: 24px;
							letter-spacing: -2px;
						`}
						type="tertiary"
					>
						{languageContent?.title}
					</Typography>
					{languageContent?.description &&
					languageContent.description.description?.length > 0 ? (
						<Typography
							css={css`
								margin-bottom: 22px;
								font-size: 20px;
								letter-spacing: -1px;
								margin-top: 0px;
							`}
							type="tertiary"
							size="xl"
							color={theme.colors.tertiary.header}
							variant="h3"
						>
							{languageContent.description.description}
						</Typography>
					) : (
						<></>
					)}
					{languageContent?.callToAction &&
					languageContent.callToAction.callToAction?.length > 0 ? (
						<Typography type="tertiary" size="md" variant="div">
							{languageContent.callToAction.callToAction}
						</Typography>
					) : (
						<></>
					)}
					{languageContent?.thirdPartyLinkLabel && languageContent?.thirdPartyLinkUrl ? (
						<Typography
							css={css`
								margin-bottom: 26px;
							`}
							variant="div"
							size="md"
							type="tertiary"
						>
							{content.moreInformation}{" "}
							<Link
								gatsby={false}
								to={languageContent.thirdPartyLinkUrl}
								type="tertiary"
							>
								{languageContent.thirdPartyLinkLabel}
							</Link>
						</Typography>
					) : (
						<></>
					)}
					{languageContent?.externalAction && languageContent?.externalActionUrl ? (
						<ButtonLink
							orientation="right"
							type="tertiary"
							label={languageContent.externalAction}
							to={languageContent.externalActionUrl}
							gatsby={false}
							size="sm"
							linkType="external"
						/>
					) : (
						<></>
					)}
					<MobileHeroImage
						css={css`
							background-image: url(${languageContent.heroImage?.file
								? languageContent.heroImage?.file?.url
								: ""});
							margin-bottom: 16px;
						`}
					/>
				</ContentSection>
				<ContentSection type="primary" vPadding="16px">
					{languageContent?.internalAction ? (
						<>
							<Typography
								variant="h3"
								type="primary"
								css={css`
									font-size: 20px;
									letter-spacing: -1px;
								`}
							>
								{content.rsvp}
							</Typography>
							<Divider
								css={css`
									margin-top: 16px;
									margin-bottom: 24px;
								`}
							/>
							<Typography
								variant="div"
								size="sm"
								type="primary"
								css={css`
									margin-bottom: 18px;
								`}
							>
								{languageContent.internalAction}
							</Typography>
							<Link to={languageContent.internalActionUrl}>
								<Button
									css={css`
										margin-bottom: 40px;
									`}
									fullwidth={true}
									label={content.rsvp + " " + content.withRyerson}
									onClick={() => {}}
								/>
							</Link>
						</>
					) : (
						<></>
					)}
					<SocialMedia
						color={theme.colors.primary.primaryBrand}
						type="primary"
						orientation="horizontal"
					/>
					<Divider
						css={css`
							margin-top: 8px;
							margin-bottom: 50px;
							color: ${theme.colors.primary.darkerGray};
						`}
					/>
					<Accordion type="primary" multiple={true}>
						<AccordionTab circled={true} header={content.generalTitle}>
							<ContentSection type="primary" vPadding="15px" hPadding="0px">
								<Typography
									variant="div"
									type="primary"
									css={css`
										margin-bottom: 5px;
									`}
									size="md"
								>
									{content.dateLabel}
									{": "}
									<Typography
										variant="span"
										weight="bold"
										color={theme.colors.primary.header}
									>
										{languageContent?.eventDates}
									</Typography>
								</Typography>
								<Typography
									variant="div"
									css={css`
										margin-bottom: 16px;
									`}
									type="primary"
									size="md"
								>
									{content.locationLabel}
									{": "}
									<Typography
										variant="span"
										weight="bold"
										color={theme.colors.primary.header}
									>
										{languageContent?.eventLocation}
									</Typography>
								</Typography>
								{languageContent?.mapLocation &&
								languageContent?.mapLocation.lon &&
								languageContent?.mapLocation.lat ? (
									<MapContainer
										css={css`
											height: 300px;
											margin-bottom: 25px;
										`}
									>
										<Map
											googleKey={process.env.GATSBY_GOOGLE_MAPS_KEY ?? ""}
											mapData={[
												{
													location: {
														lat: languageContent.mapLocation.lat,
														lng: languageContent.mapLocation.lon,
													},
													name: languageContent.eventLocation,
													id: languageContent.eventLocation,
												},
											]}
											zoomLevel={16}
										/>
									</MapContainer>
								) : (
									<></>
								)}
							</ContentSection>
						</AccordionTab>
					</Accordion>
					<Divider
						css={css`
							color: ${theme.colors.primary.darkerGray};
							margin-bottom: 24px;
						`}
					/>
					<Button fullwidth={true} label={content.registerToday} onClick={() => {}} />
				</ContentSection>
				<ContentSection type="secondary" vPadding="24px">
					<Typography variant="div" type="secondary" size="md">
						{content.contactQuestion}
					</Typography>
					<ButtonLink
						type="secondary"
						size="sm"
						linkType="internal"
						to={content.contactLinkUrl}
						label={content.contactLinkLabel}
						orientation="right"
					/>
				</ContentSection>
				<RightContainer
					css={css`
						background-color: ${theme.colors.primary.darkerGray};
						padding: 16px 21px 21px 21px;
						border-radius: 0px;
					`}
				>
					<Typography
						variant="h3"
						type="tertiary"
						css={css`
							margin-bottom: 20px;
							font-size: 20px;
						`}
					>
						{content.upcomingLabel}
					</Typography>
					{upcomingEventsToDisplay.map((event: any, index: number) => {
						return (
							<React.Fragment key={index}>
								<Divider
									css={css`
										color: ${theme.colors.primary.white};
										margin-top: 0px;
										margin-bottom: 0px;
									`}
								/>
								<UpcomingEvent>
									<UpcomingEventDetails
										css={css`
											height: 100px;
										`}
									>
										<Flex
											direction="column"
											justifyContent="space-between"
											css={css`
												height: 100%;
											`}
										>
											<FlexItem>
												<Typography
													variant="div"
													weight="bold"
													size="md"
													color={theme.colors.tertiary.header}
												>
													{event[language]?.title}
												</Typography>
											</FlexItem>
											<FlexItem>
												<Typography variant="div" size="sm" type="tertiary">
													{event[language]?.eventDates}
												</Typography>
											</FlexItem>
										</Flex>
									</UpcomingEventDetails>
									<UpcomingEventImage
										css={css`
											background-image: url(${event[language].heroImage?.file
												? event[language].heroImage?.file?.url
												: ""});
											height: 100px;
										`}
									/>
								</UpcomingEvent>
							</React.Fragment>
						);
					})}
					<Divider
						css={css`
							color: ${theme.colors.primary.white};
							margin-top: 0px;
							margin-bottom: 18px;
						`}
					/>
					<ButtonLink
						to={content.allEventsUrl}
						linkType="internal"
						type="tertiary"
						orientation="right"
						size="sm"
						label={content.allEventsLabel}
					/>
				</RightContainer>
				<Spacer
					css={css`
						height: 10px;
					`}
				/>
				<RightContainer>
					<PopularContent title={content.relatedLabel} excludeSlug={""} />
				</RightContainer>
				<Spacer
					css={css`
						height: 10px;
					`}
				/>
				<ContentSection type="tertiary" vPadding="0px" hPadding="0px">
					<StayTunedComponent parent={stayTunedParent} />
				</ContentSection>
			</Media>
		</>
	);
};

export default Events;
